import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from "../../assets/team1.png";
import img2 from "../../assets/team2.png";

function Team() {
  return (
    <div id="our-team">
      <div class="container">
        <div class="text-center">
          <h3 class="team_title11">Nosso Team</h3>
          <p class="team_title111">
            {" "}
            Há 4 anos no mercado, garantindo maior qualidade e proporcionando
            soluções para o desenvolvimento tecnológico do nosso país.
          </p>
        </div>
        <div class="row">
          <div
            class="col-md-4 wow fadeInUp"
            data-wow-offset="0"
            data-wow-delay="0.3s"
          >
            <div class="text-center">
              <img src={img1} alt="" class="img" />
              <h2>Arsénio Capenda</h2>
              <h4>Founder & CEO</h4>
            </div>
          </div>
          <div
            class="col-md-4 wow bounceInDown"
            data-wow-offset="0"
            data-wow-delay="0.3s"
          >
            <div class="text-center">
              <img src={img2} class="img" />
              <h2>David Mpindi</h2>
              <h4>Founder & CTO</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
