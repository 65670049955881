import React from "react";
import "./css/styles.css";
import "./css/swiper-bundle.min.css";
import Navbar from "../../components/Navbar";
import Carousel from "../../components/Carousel";
import Project from "../../components/Project";
import Team from "../../components/Team";
import Client from "../../components/Client";
import Logo from "../../assets/logo.png";
import img1 from "../../assets/arpetic.png";

import { countries } from "../../components/Carousel/Data";
function Presentation() {
  return (
    <>
      <Navbar />

      <body className="home">
        <div className="carousel">
          <Carousel images={countries} />
        </div>
        <div className="about">
          <p className="about_title11">Sobre Arpetic</p>
          <p className="about_title22">
            Somos uma empresa de soluções tecnológicas, juntando os objectivos
            estratégicos organizacionais + tecnologias de informação e
            apresentar as vantagens competitivas aos nossos clientes.
          </p>
        </div>

        <div>
          <Project />
        </div>
        <div>
          <Team />
        </div>
        <div>
          <Client />
        </div>
        <div class="team-containe">
          <img src={img1} alt="" class="last_image" />
        </div>

        <div className="sectionProcessAcess container-fluid">
          <div className="container d-flex flex-wrap justify-content-around align-items-center">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 d-grid align-items-center marginTop">
              <div className="footer_logo">
                <img src={Logo} class="f_image1" alt="logotipo easy vog" />
              </div>

              <p className="footer_left11">
                Centralidade do Kilamba, Quarteirão R, EdifÍcio R2 Apartamento
                12 Luanda,Angola
              </p>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-4 d-grid align-items-center marginTop">
              <p className="footer_left1">Central de apoio</p>

              <p className="footer_left1">Ligue-nos</p>
              <p className="footer_left1">
                +244 938520949 <br />
                +244 222777561
              </p>
              <p className="footer_left1">Email: info@arpetic.com</p>
            </div>
          </div>
        </div>

        <div className="container-fluid d-flex justify-content-around footer2 col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="container d-flex flex-wrap justify-content-around footer2 col-12 col-sm-12 col-md-7 col-lg-8">
            <p className="text-footer22">
              &copy; Arpetic 2022 | Todos os direitos reservados.{" "}
            </p>
          </div>
        </div>
      </body>
    </>
  );
}

export default Presentation;
