import React from 'react';
import Routes from './routes';
import './App.css';



//import { useAuth } from '../src/contexts/auth'



function App() {

  return (
   
      <Routes />
   

  );
}

export default App;
