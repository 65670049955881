import { useEffect } from "react";
import { useState } from "react";
import './style.css';
import img1 from "../../assets/Zcomercial.png";
import img2 from "../../assets/kubi.png";
import img3 from "../../assets/siga.png";
function Project() {
   
  return (
    <div class="slide-container11">
    <div class="project_name">
      <p className="project_title1">Nossos projectos</p>
    </div>
    <div className="slide-content11">
      
      <div class="card11" style={{borderRadius:25, margin:10}}>
        <div class="image-content1">
          <span class="overlay1"></span>

          <div class="card-image1">
            <img src={img1} alt="" class="card-img1" />
          </div>
        </div>

        <div class="card-content1">
          <h2 class="name1">Zcomercial</h2>
          <p class="description1">
            Software de gestão para comercial
           
          </p>
          <br/>
            <a target="_blank" href="https://www.zcomercial.com">
              Ver mais
            </a>
          
        </div>
      </div>

      <div class="card11" style={{borderRadius:25, margin:10}}>
        <div class="image-content1">
          <span class="overlay1"></span>

          <div class="card-image1">
            <img src={img2} alt="" class="card-img1" />
          </div>
        </div>

        <div class="card-content1">
          <h2 class="name1">Kubikux</h2>
          <p class="description1">Software para gestão de condomínios</p>
          
            <br/>
            <a target="_blank" href="https://kubikux.arpetic.com">
              Ver mais
            </a>
          
        </div>
      </div>

      <div class="card11" style={{borderRadius:25, margin:10}}>
        <div class="image-content1">
          <span class="overlay1"></span>

          <div class="card-image1">
            <img src={img3} alt="" class="card-img1" />
          </div>
        </div>

        <div class="card-content1">
          <h2 class="name1">Siga</h2>
          <p class="description1">Sistema Integrado de Gestão Académica</p>
          
          <br/>
            <a  target="_blank" href="https://www.siga.ao">
              Ver mais
            </a>
          
        </div>
      </div>
    </div>

    
  </div>
  );
}

export default Project;
