import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from "../../assets/lider.png";
import img2 from "../../assets/alvo.png";
import img3 from "../../assets/valores.jpg";

function Team() {
  return (
    <div class="slide-container">
      <div class="project_name1">
        <p className="project_title1">Sobre nós</p>
      </div>
      <div class="slide-content1">
        <div class="card11">
          <div class="image-content1">
            <div class="card-image1">
              <img src={img1} alt="" class="card-img1" />
            </div>
          </div>

          <div class="card-content1">
            <h2 class="name1">MISSÃO</h2>
            <p class="description1">
              Investir continuamente na capacitação dos nossos colaboradores e
              Ser agente de transformação em nossos parceiros
            </p>
            <p class="description1">
              Prover soluções de qualidade os nossos clientes, prepará-los para
              o futuro e apoiá-los no desenvolvimento dos seus negócios
            </p>
          </div>
        </div>
        <div class="card11">
          <div class="image-content1">
            <div class="card-image1">
              <img src={img2} alt="" class="card-img1" />
            </div>
          </div>

          <div class="card-content1">
            <h2 class="name1">VISÃO</h2>
            <p class="description1">
              {" "}
              Ser uma empresa de consultoria em Tecnologia da Informação,
              reconhecida por sua excelência e inovação, com uma equipe que age
              com colaboração, responsabilidade e paixão, zelando pela
              estabilidade operacional de nossos parceiros.
            </p>
            <p class="description1"></p>
            <p class="description1"></p>
          </div>
        </div>

        <div class="card11">
          <div class="image-content1">
            <div class="card-image1">
              <img src={img3} alt="" class="card-img1" />
            </div>
          </div>

          <div class="card-content1">
            <h2 class="name1">VALORES</h2>
            <p class="description1">Ética Profissional.</p>
            <p class="description1">Respeito</p>
            <p class="description1">Honestidade</p>
            <p class="description1">Comprometimento</p>
            <p class="description1">Colaboração</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
