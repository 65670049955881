import React from "react";
import "./css/styles.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Navbar from "../../components/Navbar";
import Carousel from "../../components/Carousel";
import Logo from "../../assets/logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { countries } from "../../components/Carousel/Data";
import img1 from "../../assets/arpetic.png";
function Presentation() {
  return (
    <>
      <Navbar />

      <body className="home">
        <div className="carousel">
          <Carousel images={countries} />
        </div>

        <div class="team-container">
          <div class="team_name">
            <p className="team_title1">Envia uma mensagem</p>
          </div>

          <div class="services-content">
            <Form>
              <Form.Group className="mb-3" controlId="form">
                <br />

                <div class="ad d-flex flex-wrap">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <Form.Control
                      placeholder="Seu nome"
                      className="form-control"
                      id=""
                      name=""
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <Form.Control
                      placeholder="Email"
                      className="form-control"
                      id=""
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <Form.Control
                      placeholder="Assunto"
                      type="text"
                      id=""
                      name=""
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <Form.Control
                      as="textarea"
                      placeholder="Escreva a mensagem"
                      style={{ height: "100px" }}
                    />
                  </div>

                  <div className="w-100">
                    <div className="d-flex flex-wrap">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-2">
                        <Button
                          variant="primary"
                          className="d-flex1 justify-content-center rounded-2 fw-normal text-dark borderColor"
                          type="submit"
                          style={{ backgroundColor: "#DAA520" }}
                        >
                          Enviar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>

        <div class="team-container">
          <img src={img1} alt="" class="last_image" />
        </div>

        <div className="sectionProcessAcess container-fluid">
          <div className="container d-flex flex-wrap justify-content-around align-items-center">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 d-grid align-items-center marginTop">
              <div className="footer_logo">
                <img src={Logo} class="f_image1" alt="logotipo easy vog" />
              </div>

              <p className="footer_left11">
                Centralidade do Kilamba, Quarteirão R, EdifÍcio R2 Apartamento
                12 Luanda,Angola
              </p>
            </div>

            <div className="col-12 col-sm-12 col-md-4 col-lg-4 d-grid align-items-center marginTop">
              <p className="footer_left1">Central de apoio</p>

              <p className="footer_left1">Ligue-nos</p>
              <p className="footer_left1">
                +244 938520949 <br />
                +244 222777561
              </p>
              <p className="footer_left1">Email: info@arpetic.com</p>
            </div>
          </div>
        </div>
        <div className="container-fluid d-flex justify-content-around footer2 col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="container d-flex flex-wrap justify-content-around footer2 col-12 col-sm-12 col-md-7 col-lg-8">
            <p className="text-footer2">
              &copy; Arpetic 2022 | Todos os direitos reservados a Arpetic.{" "}
            </p>
          </div>
        </div>
      </body>

      <script src="./swiper-bundle.min.js"></script>
    </>
  );
}

export default Presentation;
