import React, { useRef,useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import $ from "jquery";
import Logo from "../../assets/logo.png";
import "../Navbar/styles.css";
import "./main.css";
import "../ButtonLogin/styles.css";
import {Link} from "react-router-dom";


function Navbar() {
  $(window).scroll(function () {
    if ($(document).scrollTop() > 50) {
      $(".nav").addClass("affix");
      $(".vog").css({
        color: "#fff",
      });
      $(".menuAction__text").css({
        color: "#fff",
      });
      $(".bi-person").css({
        color: "#fff",
      });
      $(".bi-bell").css({
        color: "#fff",
      });
      $(".bi-box-arrow-right").css({
        color: "#fff",
      });
      $(".nav div.main_list ul li a").css({
        color: "#fff",
      });
      $(".linkLogin").addClass("text-white");
    } else {
      $(".nav").removeClass("affix");
      $(".vog").css({
        color: "#fff",
      });
      $(".menuAction__text").css({
        color: "#fff",
        fontWeight: "bold",
      });
      $(".bi-person").css({
        color: "#fff",
      });
      $(".bi-bell").css({
        color: "#fff",
      });
      $(".bi-box-arrow-right").css({
        color: "#fff",
      });
      $(".nav div.main_list ul li a").css({
        color: "#fff",
      });
      $(".linkLogin").addClass("text-black");
    }
  });
  const navRef = useRef();
  const [showLinks, setShowLinks]=useState(false);
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
  
     <div className={showLinks?"hidden":"Navbar"} >
           <div className="leftSide">
           <div className="logo">
                         <img src={Logo} className='logo_image'  /> 
                         <p className="easy1"><span  className="vog1"> Arpetic</span></p>
                        
                    </div>
            
        
            {
    /**       <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>*/}
       
        {/*<button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>*/}
      
        </div>
        <div className="rightSide">
        <div className="links" id={showLinks?"hidden":""}>
          <a><Link to="/">Home</Link></a>
          <a><Link to="/Sobre">Sobre</Link></a>
          <a><Link to="/Contact">Contactos</Link></a>
          </div>
          <button className="nav-btn" onClick={()=>setShowLinks(!showLinks)}>
            {
            showLinks &&
            <FaTimes />
        }
        {
           !showLinks &&
         
          <FaBars />
        }
        </button>
        </div>
   </div>
  );
}

export default Navbar;
