import { useEffect } from "react";
import { useState } from "react";
import './style.css';
import img1 from "../../assets/navrum2.png";
import img2 from "../../assets/caixafaa2.png";
import img3 from "../../assets/Vilanova.png";
import img4 from "../../assets/piaget2.png";
function Team() {
   
  return (
    <div class="clients1">
    <div class="team_name">
      <p className="team_title11">Nossos Clientes</p>
    </div>

    <div class="client1">
      <img src={img1} alt="" class="client_image1" />
      <img src={img2} alt="" class="client_image1" />
      <img src={img3} alt="" class="client_image1" />
      <img src={img4} alt="" class="client_image1" />
    </div>

    <div class="team-content"></div>
  </div>
  );
}

export default Team;
