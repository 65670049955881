import React from 'react';
import { BrowserRouter, Switch, Route,HashRouter } from 'react-router-dom';

import Splash from './pages/Splash';
import Presentation from './pages/Presentation';
import About from './pages/About';
import Contact from './pages/Contact';
import Customers from './pages/Customers';




function Routes() {
    return (

        <HashRouter>
          
                <Route path="/" exact component={Presentation} />
                <Route path="/Home" component={Presentation} />
                <Route path="/Sobre" component={About} />
                <Route path="/Contact" component={Contact} />
               
           
        </HashRouter>
    );

}

export default Routes;

