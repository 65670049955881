export const countries = [
 
  {
    image: "/images/ac.jpg",
    title1: "Soluções Tecnologicas",
    title2: "Bem vindo a Arpetic",
  },
  {
    image: "/images/ab.jpg",
    title1: "Soluções Tecnologicas",
    title2: "Bem vindo a Arpetic",
  },
 
];
