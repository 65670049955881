import { useEffect } from "react";
import { useState } from "react";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "../../assets/solucao.png";
function Team() {
  return (
    <div class="team-container1">
      <div class="team_name">
        <p className="team_title11">Serviços</p>
      </div>

      <div class="services-content">
        <div className="service_content1">
          <img src={img} alt="logotipo easy vog" id="logoSection1" />
          <div className="service_card">
            <p className="service_title1">
              DESENVOLVIMENTO DE SOLUÇÕES TECNOLOGICAS
            </p>
            <p>
              {" "}
              Para tornar as empresas cada vez mais completas por meio de
              soluções inteligentes, integradas e escaláveisque proporcionam
              maior competitividade para os negócios
            </p>
          </div>
        </div>

        <div className="service_content1">
          <img src={img} alt="logotipo easy vog" id="logoSection1" />
          <div className="service_card">
            <p className="service_title1">SERVIÇOS DE CONSULTORIA</p>
            <p>
              {" "}
              Ajudamos os nossos clientes e parceiros a suprir alguma demanda ou
              necessidade tecnológica de maneira rápida e ágil.
            </p>
          </div>
        </div>
        <div className="service_content1">
          <img src={img} alt="logotipo easy vog" id="logoSection1" />
          <div className="service_card">
            <p className="service_title1">SERVIÇOS DE OUTSOURCING</p>
            <p>
              {" "}
              Por meio da terceirização ajudamos os nossos parceiros a atingir a
              excelência
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
